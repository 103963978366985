import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from '@shared/base/base.component';
import { Address, getDefaultAddressByType } from '@shared/models/address.model';
import { Customer } from '@shared/models/customer.model';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { CartService } from '@shared/services/cart.service';
import { MemberService } from '@shared/services/member.service';
import { OrdersService } from '@shared/services/orders.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent extends BaseComponent implements OnInit {

  cart: ShoppingCart = new ShoppingCart()

  checkoutForm: FormGroup;

  isValidToOrderReview : boolean = true;
  errorMsg = '';

  deliveryMethods = [
    {
      text: "SHIPPING",
      value: "SHIPPING"
    }
  ]

  countries = [
    {
      text: "Hong Kong",
      value: "Hong Kong",
    },
    {
      text: "Macau",
      value: "Macau",
    }
  ]

  packing_methods = [
    {
      text: "Standard Packing",
      value: "Standard Packing"
    },
    {
      text: "Gifted Packing",
      value: "Gifted Packing"
    }
  ]

  user: Customer;

  constructor(private _formBuilder: FormBuilder, private router: Router, private memberService: MemberService, private cartService: CartService, private jwtAuthService: JwtAuthService, private orderService: OrdersService) { 
    super()
  }

  ngOnInit(): void {
    this.cartService.shoppingCart$.subscribe(cart => {
      this.cart = cart
    })

    this.jwtAuthService.getUser().subscribe(user => {
      this.user = user
      this.checkoutForm = this.createCheckoutForm();
    })
  }

  createCheckoutForm(): FormGroup {
    console.log("userCO", this.user);
    

    return this._formBuilder.group({
      firstname: [this.user.firstname, Validators.required],
      lastname: [this.user.lastname, Validators.required],
      email: [this.user.email, Validators.required],
      contact: [this.user.phoneNo, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      deliveryMethod: ["SHIPPING"],
      shippingAddressLine1: [getDefaultAddressByType(this.user.addresses, "SHIPPING").split(" | ")[0], Validators.required],
      shippingAddressLine2: [getDefaultAddressByType(this.user.addresses, "SHIPPING").split(" | ").length > 1 ? getDefaultAddressByType(this.user.addresses, "SHIPPING").split(" | ")[1] : "", Validators.required],
      shippingCity: [(this.user.addresses.find(addr => addr.type === "SHIPPING") || {city: ""}).city, Validators.required],
      shippingCountry: [(this.user.addresses.find(addr => addr.type === "SHIPPING") || {country: "Hong Kong"}).country, Validators.required],
      sameAsShipping: [false],
      billingAddressLine1: [getDefaultAddressByType(this.user.addresses, "BILLING").split(" | ")[0], Validators.required],
      billingAddressLine2: [getDefaultAddressByType(this.user.addresses, "BILLING").split(" | ").length > 1 ? getDefaultAddressByType(this.user.addresses, "BILLING").split(" | ")[1] : "", Validators.required],
      billingCity: [(this.user.addresses.find(addr => addr.type === "BILLING") || {city: ""}).city, Validators.required],
      billingCountry: [(this.user.addresses.find(addr => addr.type === "BILLING") || {country: "Hong Kong"}).country, Validators.required],
      packingMethod: ["Standard Packing"],
      shoppingBag: [false],
    });
  }

  submitCheckoutForm() {
    if(this.checkoutForm.valid) {
      const data = this.checkoutForm.getRawValue()
      console.log("dataCO", data);
      
      const address = new Address(data.type, data)
      console.log("addressCO", address);
      
      address.address = data.shippingAddressLine1 + (data.shippingAddressLine2 ? " | " + data.shippingAddressLine2 : '')
      address.default = true
      address.city = data.shippingCity
      address.country = data.shippingCountry
      address.type = data.deliveryMethod

      this.memberService.addAddress(address, "checkout").subscribe(user => {
        this.jwtAuthService.setUser(user)
      })
      let paymentReq = {}
      if(data.sameAsShipping) {
        paymentReq = {
          shippingMethod: data.deliveryMethod,
          shippingFee: this.cart.shippingFee,
          contact: data.contact,
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          packingMethod: data.packingMethod,
          shoppingBag: data.shoppingBag == true ? "Yes" : "No",
      
          billingAddress: {
              address: data.shippingAddressLine1 + " | " + data.shippingAddressLine2,
              country: data.shippingCountry,
              city: data.shippingCity,
              region: "852",
              postalCode: '999077',
              firstname: data.firstname,
              lastname: data.lastname,
          },
      
          shippingAddress: {
              address: data.shippingAddressLine1 + " | " + data.shippingAddressLine2,
              country: data.shippingCountry,
              city: data.shippingCity,
              region: "852",
              postalCode: '999077',
              firstname: data.firstname,
              lastname: data.lastname,
          },
    
          // remark: ruleRemark.join(" "),
          // counterCode: storage.getReferCode()
        }
      }else {
        paymentReq = {
          shippingMethod: data.deliveryMethod,
          shippingFee: this.cart.shippingFee,
          contact: data.contact,
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          packingMethod: data.packingMethod,
          shoppingBag: data.shoppingBag == true ? "Yes" : "No",
      
          billingAddress: {
              address: data.billingAddressLine1 + " | " + data.billingAddressLine2,
              country: data.billingCountry,
              city: data.billingCity,
              region: "852",
              postalCode: '999077',
              firstname: data.firstname,
              lastname: data.lastname,
          },
      
          shippingAddress: {
              address: data.shippingAddressLine1 + " | " + data.shippingAddressLine2,
              country: data.shippingCountry,
              city: data.shippingCity,
              region: "852",
              postalCode: '999077',
              firstname: data.firstname,
              lastname: data.lastname,
          },
    
          // remark: ruleRemark.join(" "),
          // counterCode: storage.getReferCode()
        }
      }
      this.orderService.inventoryEnquiry().then(rsp => {
        if (rsp == 'error') {
          this.router.navigate(["/cart"]);
        } else {
          this.orderService.createOrder(paymentReq).subscribe(order => {
            this.router.navigate([`/order-review/${order.id}`])
          },
          error => {
            console.log(error);
            this.isValidToOrderReview = false;
            this.errorMsg = error.error.message;
          })
        }

      }).catch(err => {
        console.log('error', err)
        this.router.navigate(["/cart"]);
      })
    }else {
      console.log('this.checkoutForm', this.checkoutForm)
      this.validateAllFormFields(this.checkoutForm)
    }
  }

  onChangeSameAsShipping(value) {
    if(value) {
      this.checkoutForm.get("billingAddressLine1").clearValidators()
      this.checkoutForm.get("billingAddressLine2").clearValidators()
      this.checkoutForm.get("billingCity").clearValidators()
    }else {
      this.checkoutForm.get("billingAddressLine1").setValidators(Validators.required)
      this.checkoutForm.get("billingAddressLine2").setValidators(Validators.required)
      this.checkoutForm.get("billingCity").setValidators(Validators.required)
    }

    this.checkoutForm.get("billingAddressLine1").updateValueAndValidity()
    this.checkoutForm.get("billingAddressLine2").updateValueAndValidity()
    this.checkoutForm.get("billingCity").updateValueAndValidity()
  }

  backToShoppingcart(){
    this.router.navigate(["/cart"]);
  }
}
