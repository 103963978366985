export const config = {
  apiUrl: 'https://bb-api-prod.digidumpling.com:8443/api/',
  authRoles: {
    sa: ['SA'], // Only Super Admin has access
    admin: ['SA', 'Admin'], // Only SA & Admin has access
    editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
    user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
    guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
  },
  merchantId: 'bb',
  stripeKey: 'pk_live_51IkKPkAvT2dMuO9RBiLZqhkmQ9bGYBO91zsxCi3YSwUdqw9LMAqNIDrdATIMMf3pJocQJe7I8U5P7rgoJKswDkB100kZo4I5lt',
  facebookPixelId: '243727807782345',
  googleAnalyticsTrackingCode: 'G-FC81J0KV3G',
  recaptcha: {
    siteKey: '6LdVxcAhAAAAAEVjkzfvIFL1YNdqFGI-8_ek5Phi',
  },
}
