<div *ngIf="show" class="coupon-popup-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="coupon-popup-container">
        <div class="logo-container"><img src="assets/images/coupon/bb-logo-blue-coupon.png" width="50%"></div>
        <h1 class="title">Enjoy 10% off</h1>
        <h4 class="paragraph">Sign up to get 10% off your next order. Discover exclusive offers, special events, and the latest news in the world of Brooks Brothers.</h4>
        <form [formGroup]="promotionForm" class="content">
            <app-input type="text" placeholder="Email Address" formControlName="email" [error]="checkError(promotionForm, 'email', 'A VALID EMAIL IS REQUIRED.')"></app-input>
            <app-select [options]="genders" placeholder="Gender" formControlName="gender" [error]="checkError(promotionForm, 'gender', 'PLEASE SELECT A GENDER.')"></app-select>
            <app-checkbox-tooltip label="" formControlName="marketing"></app-checkbox-tooltip>
            <div fxLayout="row" fxLayoutAlign="end center" class="button">
                <div class="send">
                    <app-button text="submit" color="var(--thm-text-color)" (onClick)="submitPromotionForm()"></app-button>
                </div>
            </div>
        </form>
        <img class="close-icon" (click)="close()" src="assets/images/icon/cross-icon-dark.png" alt="">
    </div>
</div>
<app-simple-popup *ngIf="closed" id="thank-coupon" title="Thank you for your interest!" [content2]="content2">
    <div class="popup-content">
        <div (click)="closePopup()">close</div>
    </div>
</app-simple-popup>

