import {ProductSku} from "./product-sku.model";
import {newArray} from "@angular/compiler/src/util";

export class SubmitCartItem
{
    setcurrency(value: string) {
        this.currency = value;
    }

    setgrandTotal(value: string) {
        this.grandTotal = value;
    }

    setquantity(value: string) {
        this.quantity = value;
    }
    setsubTotal(value: string) {
        this.subTotal = value;
    }
    setproductSku(value: string){
        this.productSku={id:value};
    }

    private productSku: object;
    private currency: string;
    private subTotal: string;
    private grandTotal: string;
    private quantity: string;





    /**
     * Constructor
     *
     * @param ShoppingCart
     */
    constructor(cartItem?)
    {
        cartItem = cartItem || {};
        this.productSku = {};
        this.currency = "HKD";
        this.grandTotal = cartItem.grandTotal || '';
        this.subTotal = cartItem.subTotal || '';
        this.quantity = cartItem.quantity || '';

    }
}
