<div class="order-summary-container">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>SUBTOTAL</div>
        <div>$ {{shoppingCart.subTotal | number}}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>DISCOUNT</div>
        <div>$ {{(shoppingCart | getDiscountByCartOrOrder) | number}}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>SHIPPING</div>
        <div>$ {{shoppingCart.shippingFee | number}}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>ESTIMATED TOTAL</div>
        <div>$ {{shoppingCart.grandTotal | number}}</div>
    </div>
</div>