import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { transformMenuNameToNumber } from '@shared/helpers/util';
import { Customer } from '@shared/models/customer.model';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { CommonService } from '@shared/services/common.service';
import { MenuItem, NavigationService } from '@shared/services/navigation.service';
import { CartService } from '@shared/services/cart.service';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss']
})
export class SideNavBarComponent implements OnInit {

  show: boolean = false;

  menuList: MenuItem[] = []

  user: Customer

  constructor(private jwtAuthService: JwtAuthService, 
              private router: Router, 
              private commonService: CommonService, 
              private navigationService: NavigationService,
              private cartService: CartService) { }

  ngOnInit(): void {
    this.jwtAuthService.user$.subscribe(user => {
      this.user = user
    })

    this.jwtAuthService.getUser().subscribe(user => {
      this.user = user
    })

    this.commonService.showSideNavBar.subscribe((value) => {
      this.show = value;
    })

    this.navigationService.headerItems$.subscribe(rsp => {
      this.menuList = rsp
      
    })
  }

  closeSideNavBar() {
    this.commonService.closeSideNavBar()
  }

  goToAuth() {
    this.closeSideNavBar()
    this.router.navigate(["/auth"])
  }

  goToMember() {
    this.closeSideNavBar()
    this.router.navigate(["/member-portal"])
  }

  goToSale() {
    this.closeSideNavBar()
    this.router.navigate(["/products/sale/page"])
  }

  goToSizeAndFit() {
    this.closeSideNavBar()
    this.router.navigate(["/size-and-fit-guides"])
  }

  logout() {
    this.closeSideNavBar()
    this.cartService.clearCart()
    this.jwtAuthService.signOut()
  }

}
