import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@shared/base/base.component';
import { Address } from '@shared/models/address.model';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { MemberService } from '@shared/services/member.service';

@Component({
  selector: 'app-address-update-form',
  templateUrl: './address-update-form.component.html',
  styleUrls: ['./address-update-form.component.scss']
})
export class AddressUpdateFormComponent extends BaseComponent implements OnInit {

  @Input() address: Address;

  @Output() onCancel = new EventEmitter<any>()

  types = [
    {
      text: "SHIPPING",
      value: "SHIPPING",
    },
    {
      text: "BILLING",
      value: "BILLING",
    }
  ]

  countries = [
    {
      text: "Hong Kong",
      value: "Hong Kong",
    },
    {
      text: "Macau",
      value: "Macau",
    }
  ]

  updateAddressForm: FormGroup;

  constructor(private _formBuilder: FormBuilder, private memberService: MemberService, private jwtAuthService: JwtAuthService) { 
    super()
  }

  ngOnInit(): void {
    this.updateAddressForm = this.createUpdateAddressForm()
  }

  createUpdateAddressForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.address.id],
      firstname: [this.address.firstname, Validators.required],
      lastname: [this.address.lastname, Validators.required],
      addressLine1: [this.address.address.split(" | ")[0], Validators.required],
      addressLine2: [this.address.address.split(" | ").length > 1 ? this.address.address.split(" | ")[1] : "", Validators.required],
      country: ["Hong Kong"],
      city: [this.address.city, Validators.required],
      contact: [this.address.contact, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      type: [this.address.type, Validators.required],
      default: [this.address.default]
    });
  }

  submitUpdateAddressForm() {
    if(this.updateAddressForm.valid) {
      const data = this.updateAddressForm.getRawValue()
      const address = new Address(data.type, data)
      address.address = data.addressLine1 + (data.addressLine2 ? " | " + data.addressLine2 : data.addressLine2)
      this.memberService.updateAddress(address).subscribe(user => {
        this.jwtAuthService.setUser(user)
      })
    }else {
      this.validateAllFormFields(this.updateAddressForm)
    }
  }

  cancel() {
    this.onCancel.emit()
  }

}
