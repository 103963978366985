<div (click)="click()" class="checkbox" [ngClass]="{'clicked': value, 'mb-1': withMarginBottom}">
    <div>
        <span>I agree to receiving direct marketing materials by Brooks Brothers Hong Kong Limited. </span>
        <span class="tooltip">
            Read Declaration Here
            <span class="tooltiptext">
                <div class="pb-1"><u>Personal Information Collection Statement</u></div>
                <div class="pb-1">By submitting this form, you understand and agree to the following:</div>
                <div class="pb-1">(i) Brooks Brothers may collect, use, store any personal data that you provide under this application in accordance with its Privacy Policy available at <a target="_blank" href="https://www.brooksbrothers.com.hk/#/posts/privacy-policy">https://www.brooksbrothers.com.hk/#/posts/privacy-policy</a>;</div>
                <div class="pb-1">(ii) your personal data held by Brooks Brothers will be kept confidential at all times, except that it may be provided on a confidential basis to third parties in accordance with its Privacy Policy (including for Brooks Brothers’ daily operation);</div>
                <div class="pb-1">(iii) Having obtained your consent, your personal data may be used by Brooks Brothers to contact you in relation to news, events and promotional offers from Brooks Brothers and its business partners in accordance with its Privacy Policy;</div>
                <div class="pb-1">(iv) You understand you can opt-out of receiving such direct marketing communications at any time by contacting our Data Privacy Officer at <a href="mailto:privacyofficer@gcr.brooksbrothers.hk">privacyofficer@gcr.brooksbrothers.hk</a> or by post to our Data Privacy Officer at Brooks Brothers, Unit 2201A, 22/F, One Island South, 2 Heung Yip Road, Wong Chuk Hang, Hong Kong or by clicking the unsubscribe link at the bottom of such emails.</div>
                <div>(v) your application for (and your use of) the Brooks Brothers Membership will be governed by the Terms and Conditions of Use. In the above, "Brooks Brothers" means Brooks Brothers Hong Kong Limited and its related companies.</div>
            </span>
        </span>
    </div>
</div>
